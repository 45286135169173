import React from 'react'

import Container from './container'
import * as styles from './online-stores-banner.module.css'

const OnlineStoreBanner = () => {

  return (
    <div className={styles.bannerContainer}>
      <Container className={styles.bannerContent}>
        <div>
          <h2>Trouvez-nous en ligne!</h2>
          <a href='https://chefcookit.com/' className="button green" target="_blank" rel="noreferrer noopener">Cook it</a>
          <a href='https://www.marche57.com/' className="button green" target="_blank" rel="noreferrer noopener">Marché 57</a>
          <a href='https://second-life.ca/' className="button green" target="_blank" rel="noreferrer noopener">Second Life</a>
        </div>
      </Container>
    </div>
  )
}

export default OnlineStoreBanner
